<template>
  <div>
    <!-- title 组件 -->
    <common-title
        :titleProp="titleProp"
        @addTemplate="addTemplate"

    />
    <!--table 组件 -->
    <common-table
        :tableData="tableData"
        :tableProp="tableProp"
        @pageCurrentChange="pageCurrentChange"
        @editTemplate="editTemplate"
        @deleteTemplate="deleteTemplate"

    />
    <!--addDialog 组件 -->
    <el-dialog
        :append-to-body="true"
        :title="addTemplateData.title"
        :visible.sync="addTemplateData.isDialog"
        :top="addTemplateData.top"
        :width="addTemplateData.width"
    >
      <div class="common-add-dialog">
        <ul>
          <li>
            <span :style="{'width':addTemplateData.labelWidth}">模板名称</span>
            <el-input v-model="addTemplateData.addDialogShowData.name"></el-input>
          </li>
          <li>
            <span :style="{'width':addTemplateData.labelWidth}">事项名称</span>
            <el-select v-model="addTemplateData.addDialogShowData.type_name">
              <el-option value="立项" label="立项"></el-option>
              <el-option value="成单" label="成单"></el-option>
              <el-option value="商务结题" label="商务结题"></el-option>
              <el-option value="技术结题" label="技术结题"></el-option>
              <el-option value="报销" label="报销"></el-option>
              <el-option value="转正" label="转正"></el-option>
            </el-select>
          </li>
          <li>
            <span :style="{'width':addTemplateData.labelWidth}">模板描述</span>
            <el-input v-model="addTemplateData.addDialogShowData.description"></el-input>
          </li>
          <common-shenp :shenP="addTemplateData.shenP"></common-shenp>
        </ul>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addTemplateData.isDialog=false">取 消</el-button>
        <el-button type="primary" @click="addTemplateSub">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
// import {timer} from '@/assets/js/comment'
import {
  getTemplate,
  addTemplate,
  deleteTemplate,
  editTemplate,
  getTemplateDetial
} from '@/api/basic_management/template_management'

export default {
  components: {},
  props: [],
  data() {
    return {
      //title 数据源
      titleProp: {
        name: '事项模板管理',//标题名称
        butOperate: [{
          name: '添加',
          type: 'primary',
          method: 'addTemplate',
          id: 2000,
        }]
      },
      //table 数据源
      tableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: true, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [/*{
          title: 'ID',
          field: 'id',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, */{
          title: '模板名称',
          field: 'name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '事项名称',
          field: 'type_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '创建日期',
          field: 'created_at',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        }, {
          title: '操作',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 2,
          tableOperate: [{
            name: '编辑',
            type: 'primary',
            method: 'editTemplate',
            status: '20000',
            id: 20000,
          }, {
            name: '删除',
            type: 'danger',
            method: 'deleteTemplate',
            status: '20000',
            id: 20000,
          }],
        }],
      },
      //addDialog数据源
      addTemplateData: {
        isDialog: false,//dialog是否显示
        title: '添加事项模板',// dialog 标题
        top: '50px', // dialog 离浏览器top
        width: '900px', // dialog 长度，可以是百分比
        labelWidth: '160px', // label 长度
        //dialog 显示数据
        addDialogShowData: {
          name: '',
          type_name: '',
          description: '',
          content_json: [],
        },
        shenP:{
          labelWidth: "160px",
          shenPr:[],
          type: 1,
          shenpm: '',
          data:[],
        },
        // 下拉框的 options和单选框，多选框
      },
      //表格数据
      tableData: [],
      editId:'',
    };
  },
  created() {
    this.initLoad();
  },
  mounted() {
  },
  methods: {
    //表格初始化
    initLoad() {
      this.$store.state.loading = true;
      let params = {
        page: this.tableProp.page.current_page,
        // keywords: this.tabsProp.keywords,
        // this.tabsProp.active //tabs 当前的值
        // name: this.tabsProp.heighKewWordField.name
      }
      getTemplate(params).then((result) => {
        console.log(result)
        let res = result.data;
        this.tableProp.page.current_page = res.current_page;
        this.tableProp.page.total = res.total;
        this.tableProp.page.size = res.per_page;
        this.tableData = res.data
      })
    },
    //title点击 添加类型 按钮
    addTemplate() {
      console.log('添加类型')
      this.addTemplateData.title = '添加事项模板'
      this.editId = ''
      this.addTemplateData.addDialogShowData = this.$options.data().addTemplateData.addDialogShowData;
      this.addTemplateData.shenP = this.$options.data().addTemplateData.shenP;
      this.addTemplateData.isDialog = true;
    },

    //table 编辑 按钮点击
    editTemplate(row, index) {
      console.log(row)
      console.log(index)
      console.log('编辑 点击')
      this.editId = row.id
      this.addTemplateData.title = '编辑事项模板'
      this.addTemplateData.addDialogShowData = this.$options.data().addTemplateData.addDialogShowData;
      this.addTemplateData.shenP.data = this.$options.data().addTemplateData.shenP.data;
      getTemplateDetial({id: this.editId}).then((result)=>{
        this.addTemplateData.addDialogShowData = result.data
        this.addTemplateData.shenP.data = JSON.parse(result.data.content_json)
      })
      this.addTemplateData.isDialog = true
    },
    //table 作废 按钮点击
    deleteTemplate(row, index) {
      console.log(row)
      console.log(index)
      console.log('作废 点击')
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteTemplate({id: row.id}).then((result) => {
          this.$message({
            message: result.msg,
            type: 'success'
          });
          this.tableData.splice(index, 1)
        })
            .catch((error) => {
              console.log(22);
              this.$message({
                message: error.msg,
                type: 'error'
              });
            })
      })
    },
    //table 分页 点击当前页
    pageCurrentChange(val) {
      this.tableProp.page.current_page = val
      console.log('当前页:' + val);
      this.initLoad();
    },
    // addDialog 点击确定
    addTemplateSub() {
      console.log(123)
      let subData = {...this.addTemplateData.addDialogShowData}  //ES6深拷贝
      subData.content_json = JSON.stringify(this.addTemplateData.shenP.data)
      if (this.editId){
        console.log('编辑')
        subData.id = this.editId
        console.log(subData)
        editTemplate(subData).then((result) => {
          this.addTemplateData.isDialog = false;
          this.$message({
            message: result.msg,
            type: 'success'
          });
          this.initLoad()
        })
        .catch((error) => {
              console.log(22);
              this.$message({
                message: error.msg,
                type: 'error'
              });
            })
      } else {
        console.log('添加')
        console.log(subData)
        addTemplate(subData).then((result) => {
          this.addTemplateData.isDialog = false;
          this.$message({
            message: result.msg,
            type: 'success'
          });
          this.initLoad()
        })
        .catch((error) => {
              console.log(22);
              this.$message({
                message: error.msg,
                type: 'error'
              });
            })
      }
    },
  },
};
</script>
<style lang="scss">
.common-add-dialog {
  li {
    //文本域
    .el-textarea__inner {
      padding: 5px 8px;
    }
  }
}

</style>
<style lang="scss" scoped>
.common-add-dialog {
  li:nth-last-of-type(1){
    margin-bottom: 0;
    align-items: baseline;
  }
  li {
    margin-bottom: 15px;

    span {
      width: 120px;
      text-align: right;
      margin-right: 15px;
      flex: 0 0 auto;
    }

    @include flex();
    //下拉框
    .el-select {
      flex: 1;
    }
    //单选
    .el-radio{
      margin: 3px 30px 3px 0;
    }
    //多选
    .el-checkbox{
      margin: 3px 30px 3px 0;
    }

  }
}
</style>
